export const routerPath = {
  首页: '/',
  软件配套: '/facilities',
  硬件设施: '/facilities',
  企业培训: '/company',
  /* 合作共赢  */
  合作共赢: '/cooperate/channel',
  渠道合作: '/cooperate/channel',
  生态合作: '/cooperate/ecology',
  /* 关于我们 */
  关于我们: '/about/introduction',
  公司介绍: '/about/introduction',
  团队师资: '/about/team',
  合资企业: '/about/partners',

  /* 最新资讯 */
  最新资讯: '/news/list',
  行业资讯: '/news/list',
  认证信息: '/news/authentication',
  招聘需求: '/news/recruitment',
  搜索: '/search',
  思科认证: '/courses/cisco',
  华为认证: '/courses/huawei',
  深信服认证: '/courses/sangfor',
  锐捷认证: '/courses/ruijie',
  奇安信认证: '/courses/qiAnXin'
}

export const BreadcrumbLink = {
  default: [{ label: '首页' }],
  软件配套: [{ label: '首页' }, { label: '硬件设施' }, { label: '软件配套' }],
  硬件设施: [{ label: '首页' }, { label: '硬件设施' }],
  企业培训: [{ label: '首页' }, { label: '企业培训' }],
  搜索: [{ label: '首页' }, { label: '搜索' }],
  师资团队: [{ label: '首页' }, { label: '师资团队' }],
  招聘需求: [{ label: '首页' }, { label: '最新资讯' }, { label: '招聘需求' }],
  行业资讯: [{ label: '首页' }, { label: '最新资讯' }, { label: '行业资讯' }],
  认证信息: [{ label: '首页' }, { label: '最新资讯' }, { label: '认证信息' }],

  合作企业: [{ label: '首页' }, { label: '关于我们' }, { label: '合作企业' }],
  渠道合作: [{ label: '首页' }, { label: '合作共赢' }, { label: '渠道合作' }],
  生态合作: [{ label: '首页' }, { label: '合作共赢' }, { label: '生态合作' }],

  公司介绍: [{ label: '首页' }, { label: '关于我们' }, { label: '公司介绍' }],
  // 团队师资: [{ label: '首页' }, { label: '关于我们' }, { label: '团队师资' }],
  // 合资企业: [{ label: '首页' }, { label: '关于我们' }, { label: '合资企业' }]
}
